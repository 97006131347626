<template>
 <div class="emptyBox">
   <img src="@/assets/empty.png" class="icon" />
   <span class="title">{{title}}</span>
 </div>
</template>

<script>
export default {
  props:{
    title:{
      type:String,
      default:'暂无数据~'
    }
  },
  data () {
    return {
      
    }
  }
}
</script>

<style scoped lang="scss">
.emptyBox{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .icon{
    width: 112px;
    height: 105px;
  }
  .title{
    font-size: 14px;
    color: #222222;
    padding-top: 16px;
  }
}
</style>