<template>
 <div class="tableBox">
   <div class="tableHeader">
     <span class="flex1">账单名称</span>
     <span class="width150">账单分类</span>
     <span class="width150">账单金额(元)</span>
     <span class="width65">操作</span>
   </div>

   <div class="tableCon" v-for="(item,index) in data" :key="index">
     <div class="nameBox"><span>付款方</span><i>{{item.paymentCompany}}</i></div>
     <div class="tableTrBox">
       <div class="tableTr">

       <div class="left"  v-for="itemm in item.incomeList" :key="itemm.id">
         <div class="top">
           <span class="flex1">{{itemm.title}}
            <div class="bottom"><span>{{ $timestampToDate(itemm.createTime) }}</span></div>
           </span>
           <span class="width150">{{itemm.typeString}}</span>
           <span class="width150">{{itemm.totalMoney|money}} <i @click="goDetail(itemm.id)">详情</i></span>
         </div>
         
       </div>

     </div>
     <div class="right width65">
         <span @click="goWithdrawal(item)">去提现</span>
       </div>
     </div>
   </div>
 </div>
</template>

<script>
import wallet from '../../api/wallet'
 
export default {
  props:{
    data:{
      type:Array,
      default:()=>{
        return []
      }
    }
  },
  created(){
      wallet.myBankMsg().then(r => {
        // 没有银行卡
        if (r.data === null) {
          this.cardInfo = {
            status: null
          }
        } else {
          this.cardInfo = r.data
        }
      })
  },
  data () {
    return {
      cardInfo:''
    }
  },
  methods:{
    //去提现
    goWithdrawal(item){
          if(this.cardInfo.status===null) {
           this.$popup({ msg:'您还未绑定银行卡，绑定后即可打款哦~',type:'warning'})
           return
       }else if(this.cardInfo.status===0) {
           this.$popup({msg:'银行卡审核中，请等待审核通过后再进行提现操作',type:'warning'})
           return
       }else if (this.cardInfo.status===2){
           this.$popup({msg:'您的银行卡审核失败，重新绑定后即可打款哦~',type:'error'})
           return
       }
      this.$emit('goWithdrawal',item)
    },
    //查看详情
    goDetail(id){
    //   this.$emit('goDetail',id)
    this.$router.push({path:'/BillDetails',query:{id:id}})
    },
  },
  filters:{
   money(val){
       return val.toFixed(2)
   }
  }
}
</script>

<style scoped lang="scss">
.tableBox{
  width: 100%;
  .tableHeader{
    width: 100%;
    height: 44px;
    background: #FFF6FA;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 2px solid #FF90B1;
    span{
      padding: 0 15px;
      font-size: 14px;
    font-family: PingFang SC, PingFang SC-Regular;
    font-weight: 400;
    text-align: LEFT;
    color: #333333;
    }
  }
  .tableCon{
    .nameBox{
      background: #F9F9F9;
      border-top: 1px solid #EBEBEB;
      margin-top: 17px;
      height: 44px;
      line-height: 44px;
      span{
        color: #6E6E6E;
        font-size: 12px;
        padding-right: 3px;
        padding-left: 10px;
      }
      i{
        color: #202020;
        font-style: normal;
        font-size: 12px;
      }
    }
    .tableTrBox{
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .tableTr{
      border-right: 1px solid rgba(235,238,245,0.6);
      flex: 1;
      .left{
        border-bottom: 1px solid rgba(235,238,245,0.6);
        flex: 1;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        .top{
          display: flex;
          align-items: center;
          flex: 1;
          flex-wrap: wrap;
          span{
            padding: 15px 15px 0 15px;
            font-size: 12px;
            color: #666;
          }
          i{
          font-style: normal;
          color: #BFBFBF;
          font-size: 12px;
          cursor: pointer;
        }
        }
        .bottom{
          height: 30px;
          line-height: 30px;
          width: 100%;
          padding: 0 15px 0px 0px;
          >span{
              padding-left: 0px;
            color: #BDBDBD;
            font-size: 12px;
          }
        }
      }
    }
    .right{
        padding: 15px;
        display: flex;
        border-bottom: 1px solid rgba(235,238,245,0.6);
         align-items: center;
         
        span{
          color: #FF004D;
          font-size: 14px;
          cursor: pointer;
        }
      }
    }
  }
  .width150{
    width: 150px;
  }
  .width65{
    width: 65px;
    justify-content: center;
  }
  .flex1{
    flex:1
  }
}
</style>