<template>
 <div class="tabBox">
   <template v-for="(item,index) in tabData">
    <div @click="itemClick(item)" class="tabItem" :class=" item.checked ? 'actived':''" :key="index">
      <span>{{item.name}}</span>
      <i>{{item.totalMoney.toFixed(2)}}元</i>
    </div>
   </template>
 </div>
</template>

<script>
export default {
  props:{
    tabData:{
      type:Array,
      default:()=>{
        return []
      }
    }
  },
  data () {
    return {
      
    }
  },
  methods: {
    itemClick(item){
      this.$emit('itemClick',item)
    }
  },
}
</script>

<style scoped lang="scss">
.tabBox{
  display: flex;
  margin-bottom: 34px;
  .tabItem{
    margin-right: 16px;
    background: #f9f9f9;
    border-radius: 2px;
    padding: 10px 16px;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    width: 88px;
    height: 33px;
    span{
      color: #333333;
      font-size: 12px;
      padding-bottom: 2px;
    }
    i{
      color: #333333;
      font-style: normal;
      font-size: 10px;
    }
  }
  .actived{
    background: #ff004d;
    span{
      color: #FFFFFF;
    }
    i{
      color: #FFFFFF;
    }
  }
}

</style>