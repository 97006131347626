<template>
  <div class="works">
    <left-menu current="Income" />
    <div class="main">
      <el-row>
        <el-col :span="12">
          <el-breadcrumb separator="/">
            <el-breadcrumb-item to="/income">我的收益</el-breadcrumb-item>
            <el-breadcrumb-item>提现申请</el-breadcrumb-item>
          </el-breadcrumb>
        </el-col>
        <el-col :span="12" style="text-align: right"
          ><el-link type="primary" href="#/withdrawal-list"
            >提现记录</el-link
          ></el-col
        >
      </el-row>

      <el-divider></el-divider>
      <IncomeTab  v-if="tabData.length>0" @itemClick="itemClick" :tabData="tabData" />
      <div class="title">
        您有<span>{{ doneMoney.toFixed(2) }}</span
        >元收益待提现
      </div>
      <div class="tips">选择提现账单</div>
      <el-divider></el-divider>

      <CustomTable
        v-loading="loading"
        @goWithdrawal="goWithdrawal"
        @goDetail="goDetail"
        :data="tableData"
      />
      <div v-if="tableData.length == 0 && !loading" style="padding-top:100px"><Empty title="暂无可提现收益～" /></div>
    </div>
  </div>
</template>

<script>
import LeftMenu from "@/components/LeftMenu.vue";
import {
  paymentCompanyData,
  dictInfo,
  incomeList,
  statisticsByCompany,
} from "../api/index";

import CustomTable from "@/components/withdrawal/CustomTable.vue";
import IncomeTab from "@/components/withdrawal/IncomeTab.vue";
import Empty from "@/components/withdrawal/Empty.vue";
export default {
  components: { LeftMenu, CustomTable, IncomeTab,Empty },
  data() {
    return {
      headerStyle: {
        background: "#F8F8F8",
        color: "#333",
        fontSize: "14px",
        fontWeight: "400",
        borderBottom: "none",
      },
      tabData: [],
      tableData: [],
      typeData: [],
      //待提现的收益（税前）
      doneMoney: 0,
      loading:true,
    };
  },

  created() {
    this.getStatisticsByCompany();

    //获取待提现金额
    incomeList({status:1}).then((res) => {
      this.doneMoney = res.data.doneMoney || 0;
    });
  },

  methods: {

    //去提现
    goWithdrawal(item) {
      this.$router.push('/withdrawal/' + JSON.stringify(item))
    },

    //查看详情
    goDetail(id) {
      console.log("id:", id);
    },

    //点击tab
    itemClick(item) {
      if (!item.checked) {
        this.tabData.forEach((data) => {
          if(data.type == item.type){
            data.checked = true
            this.loading = true
            this.getList(item.type)
          }else{
            data.checked = false
          }
        });
      }
    },

    //获取分类
    getStatisticsByCompany() {
      statisticsByCompany({ dimension: 1, type: 0 })
        .then((res) => {
          let { data } = res;
          data.forEach((item, index) => {
            if (index == 0) {
              item.checked = true;
            } else {
              item.checked = false;
            }
          });
          this.tabData = data;
        })
        .then(() => {
          //收入分类匹配
          dictInfo({ codes: ["10016"] })
            .then((res) => {
              this.typeData = res.data;
            })
            .then(() => {
              if(this.tabData.length > 0){
                this.getList(this.tabData[0].type);

              }else{
               this.loading = false
              }

            });
        });
    },

    //获取提现账单列表
    getList(type) {
        // console.log(1123)
      paymentCompanyData({ type }).then((res) => {
        let { data } = res;
        data.forEach((item) => {
          if (item.incomeList.length > 0) {
            item.paymentCompany = item.incomeList[0].paymentCompany;
            item.incomeList.forEach((chItem) => {
              this.typeData.forEach((t) => {
                if (t.label == chItem.type) {
                  chItem.typeString = t.value;
                }
              });
            });
          }
        });
        this.tableData = data;
      }).finally(()=>{
        this.loading = false
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/works.scss";
.main {
  box-sizing: border-box;
  ::v-deep .el-divider {
    background-color: #f4f4f4;
  }
  .title {
    font-size: 24px;
    color: #333;
    font-weight: 500;
    span {
      color: #ff477e;
    }
  }
  .tips {
    color: #333;
    font-size: 16px;
    padding-top: 30px;
  }
}
</style>
